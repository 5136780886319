$(document).ready(function(){
   !function(){
        $('.h-menu__close').on('click',function(){
            $('.h-menu').removeClass('h-menu--active');
            $('.submenu').removeClass('submenu--active');
            $('.h-menu__back').removeClass('h-menu__back--active')
        });
        $('.h-menu__back').on('click',function(){
            $('.submenu').removeClass('submenu--active');
            $('.h-menu__back').removeClass('h-menu__back--active')
        });
        $('.h-menu__link').on('click',function(e){
            var sub=$(this).parent().find('.submenu');
           /// console.log(sub);
            if(sub.length>0){
                e.preventDefault();
                sub.addClass('submenu--active');
                $('.h-menu__back').addClass('h-menu__back--active')
            }
        });
        $('.header__menuopen').on('click',function(){
            $('.h-menu').addClass('h-menu--active')
        });
   }();
});